
import {Options, Vue} from "vue-class-component";
import {BillInterface} from "@/models/BillInterface";
import BillCard from "@/components/Bills/BillCard.vue";
import '@splidejs/vue-splide/css';
import {BillServices} from "@/services/BillServices";
import HeaderComponent from '@/components/layout/HeaderComponent.vue'
import vSelect from 'vue-select'

@Options({
  inject: ['billServices'],
  components: {
    BillCard,
    HeaderComponent,
    vSelect
  }
})

export default class BillsListAll extends Vue {
  protected billServices!: BillServices
  public billsList: BillInterface[] = []
  public billNames: Array<string> = []
  protected selectedName: string = ''
  public filteredCurrencies: Array<string> = []
  public companyId: number = 0


  async mounted() {
    this.companyId = Number(this.$route.params.companyId)
    const bills = await BillServices.getCompanyBills(this.companyId)
    this.billsList = await BillServices.groupBills(bills)
    this.billNames = await BillServices.getBillsNames(this.companyId)
  }
  public setFilterByCurrencies(event: Event, val: string) {
    const target = event.target as HTMLInputElement

    if (target.checked) {
      this.filteredCurrencies.push(val)
    } else {
      this.filteredCurrencies = this.filteredCurrencies.filter(e => e !== val)
    }
    this.filterAll()
  }
  public setFilterByName(val: string) {
    this.selectedName = val
    this.filterAll()
  }
  public async filterAll() {
    this.billsList = await BillServices.filterAll(this.companyId, this.selectedName, this.filteredCurrencies)
  }
}
