

import { Options, Vue } from "vue-class-component";
import SlideModal from "@/components/modal/slide-modal.vue";
import router from "@/router";
import { LocalService } from "@/services/LocalService";
import { CompanyBills } from "@/lib/CompanyBillsInterface";
import { Company } from "@/lib/CompanyInterface";
import { FormatHelper } from "@/lib/formatHelper"
import { notify } from "@kyvg/vue3-notification";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'



@Options({
  components: {
    SlideModal,
  },
  validations: {
    newName: { required },
    newBal: { required },
    newCurr: { required },
    newCourse: { required },
  }
})

export default class LocalCompanyPage extends Vue {
  public newName: string = '';
  public newBal: number = 0;
  public newCurr: string = '';
  public newCourse: number = 0;
  protected createNew: boolean = false;
  public yourCompany: Company = { id: 0, name: '', balance: 0, bills: [] };
  protected totalBalance: number = 0;
  public removeBillModal: boolean = false;
  protected removeCompanyModal: boolean = false;
  public companyToDelete: number | undefined;
  protected editableMode: boolean = false;
  protected formatHelper: FormatHelper = new FormatHelper()
  submitted: boolean = false
  v$: any = useVuelidate();

  public mounted() {
    this.yourCompany = LocalService.getLocalCompany(Number(this.$route.params.id))
    this.updateTotal()
  }
  public submitNewBill() {
    this.submitted = true
    this.v$.$touch();
    if (this.v$.$invalid) {
      return;
    } else {
      this.submitted = false
    }

    const newCompanyBills: CompanyBills = { id: 0, name: this.newName, balance: this.newBal, currency: this.newCurr, course: this.newCourse }
    this.yourCompany.bills.push(newCompanyBills)
    this.yourCompany.bills.forEach((o, i) => {
      o.id = (i + 1)
    });
    this.newName = '';
    this.newBal = 0;
    this.newCurr = '';
    this.newCourse = 0;
    this.createNew = false;

    LocalService.updateLocalCompany(this.yourCompany)
    this.updateTotal()
  }
  public updateTotal() {
    this.totalBalance = 0
    Object.values(this.yourCompany.bills as CompanyBills[]).forEach((el: CompanyBills) => {
      this.totalBalance += el.balance * el.course;
    })
    this.yourCompany.balance = this.totalBalance
    LocalService.updateLocalCompany(this.yourCompany)
  }
  public removeBill(index: number) {
    this.removeBillModal = true;
    this.companyToDelete = index
  }
  public removeCompanyConfirm() {
    LocalService.removeCompany(this.yourCompany.id)
    this.removeCompanyModal = false
    router.push({ path: `/` })
  }
  public removeBillConfirm() {
    if (this.companyToDelete) {
      this.yourCompany.bills = this.yourCompany.bills.filter(item => {
        if (item.id !== this.companyToDelete)
          return item;
      });
      LocalService.updateLocalCompany(this.yourCompany)
      this.updateTotal()
    }
    this.removeBillModal = false
  }

  public editBill() {
    let isCorrect = true;
    this.yourCompany.bills.forEach(el => {
      if (!el.name || !el.currency || !el.course) {
        isCorrect = false;
      }
    })

    if (isCorrect) {
      this.editableMode = false
      LocalService.updateLocalCompany(this.yourCompany)
      this.updateTotal()
    } else {
      notify({
        group: 'auth',
        text: 'Validation error',
        type: "error"
      })
    }
  }

  public cancelBill() {
    this.editableMode = false
    this.yourCompany = LocalService.getLocalCompany(this.yourCompany.id)
  }

}
