import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "total" }
const _hoisted_2 = { class: "bills__currencies" }
const _hoisted_3 = { class: "bills__strong" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('bills__card_' + (_ctx.data.color ? _ctx.data.color : 'grey')),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goDetails(_ctx.data.id)))
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.data.name), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.currencies, (currency, index) => {
        return (_openBlock(), _createElementBlock("p", { key: index }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(currency.name) + ":", 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$formatNumber(currency.val)), 1)
        ]))
      }), 128))
    ])
  ], 2))
}