
import router from "@/router";
import { computed } from "vue";
import { store } from "@/vuex/store";
import { Company } from "@/lib/CompanyInterface";
import { FormatHelper } from "@/lib/formatHelper"
import { notify } from "@kyvg/vue3-notification";
import { Options, Vue } from "vue-class-component";
import { LocalService } from "@/services/LocalService";
import SlideModal from "@/components/modal/slide-modal.vue";
import { SelectedService } from "@/services/Company/SelectedService";
import SettingStructure from "@/components/SettingStructureComponent.vue";

type MainBalance = {
  [key: string]: number
}

@Options({
  components: {
    SlideModal,
    SettingStructure
  }
})

export default class LocalCompany extends Vue {
  protected selectedService: SelectedService = new SelectedService();
  yourLocComps: Company[] = [];
  mainCompanies: Company[] = [];
  public mainBalance: MainBalance = {}
  newName: string = '';
  createNew: boolean = false;
  protected formatHelper: FormatHelper = new FormatHelper()

  public filteredMain = computed(() => {
    return this.mainCompanies.filter((s) => this.showCompany(s.id));
  });

  public showCompany(id: number): boolean {
    return store.state.displayLocal.includes(id);
  }

  async mounted() {
    this.yourLocComps = LocalService.getLocalCompanies()
    this.mainCompanies = await this.selectedService.all();
  }

  toggleCreateMode() {
    this.createNew = !this.createNew;
  }
  submitNewLocal(name: string) {
    const companyNameMinLength = 2
    if (name.length < companyNameMinLength) {
      notify({
        group: 'auth',
        text: 'Min length: ' + companyNameMinLength + ' symbol',
        type: "error"
      })

      return false
    }

    LocalService.submitNewLocal(name)
    this.createNew = false;
    this.yourLocComps = LocalService.getLocalCompanies()
  }
  openCompany(comp: number) {
    router.push({ path: `/local-company/${comp}` })
  }
  openMain(comp: number): void {
    router.push(`/company-details/${comp}`)
  }
  shorterName(name: string) {
    return name.substring(0, 2);
  }
  public getMainBalance(): number {
    let balance = 0;
    this.mainCompanies.forEach((company) => {
      if (this.showCompany(company.id)) {
        balance+= (Number(company.balance) || 0);
      }
    });
    return balance;
  }
  public getLocalBalance(): number {
    return LocalService.getTotalBalance() + this.getMainBalance();
  }

}
