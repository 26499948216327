import { OldCompanyBillInterface } from "@/lib/Bill/OldCompanyBillInterface";
import { CompanyBills } from '@/lib/CompanyBillsInterface';
import { Company } from "@/lib/CompanyInterface";
import { LocalService } from "@/services/LocalService";
import {ApiService} from "@/services/ApiService";

export class MigrateDataService {
    private oldStorageKey: string = 'pp';
    private newCompanyName: string = 'Personality';
    protected apiService!: ApiService;

    public async getOldBills() {
        const pp = await ApiService.getMigrateData();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return pp.pp;
    }

    public getNewCompany(): Company
    {
        let company = LocalService.getLocalCompanyByName(this.newCompanyName);
        if (!company) {
            LocalService.submitNewLocal(this.newCompanyName);
            company = LocalService.getLocalCompanyByName(this.newCompanyName);
        }

        return company;
    }

    public convertOldBills(bills: Array<OldCompanyBillInterface>, newBills: Array<CompanyBills>): Array<CompanyBills>
    {
        const arr: Array<CompanyBills> = newBills;
        let lastIndex = newBills.at(-1)?.id || 0;
        bills.forEach((bill) => {
            const exist = arr.find((value: CompanyBills) => {
                if (value.name === bill.name
                    && value.balance === bill.value
                    && value.currency === bill.currency
                    && value.course === bill.currencyValue ) {
                    return true;
                }
                return false;
            });
            if (exist) {
                return;
            }
            arr.push({
                id: ++lastIndex,
                name: bill.name,
                balance: bill.value,
                currency: bill.currency,
                course: bill.currencyValue
            });
        })

        return arr;
    }

    async migrate(): Promise<boolean> {
        return new Promise((resolve) => {
            const oldBills = this.getOldBills();

            oldBills.then((oldBills) => {
                if (!oldBills) { resolve(true) }
                const company = this.getNewCompany();
                company.bills = this.convertOldBills(oldBills, company.bills);

                LocalService.updateLocalCompany(company);
                resolve(true);
            });
        });
    }
}
