
import {Options, Vue} from "vue-class-component";
import LineChart from "@/components/charts/LineChart.vue";
import {SummaryInterface} from "@/models/SummaryInterface";
import {ApiService} from "@/services/ApiService";
import SummaryTable from "@/components/Summary/SummaryTable.vue";
import moment from "moment";

export interface DatasetInterface {
  label: string,
  borderColor: string,
  data: number[]
}

@Options({
  components: {
    LineChart,
    SummaryTable
  }
})

export default class Summary extends Vue {
  public summary: SummaryInterface[] = ApiService.getSummary()
  public dataset: Array<DatasetInterface> = []
  public labels: Array<string> = []
  public currentYear = new Date().getFullYear()

  public changeYear(year: number) {
    this.currentYear = year

    this.labels = []
    this.summary = []
    this.formDataset()
  }

  public formDataset() {
    this.dataset = []

    ApiService.getStatisticByYear(Number(this.$route.params.companyId), this.currentYear).then(res => {
      res.forEach(el => {
        const record: SummaryInterface = el.data
        const recordMonth = new Date(record.date.date).getMonth()
        const month = moment().month(recordMonth).format('MMMM')

        if (this.labels.indexOf(month) === -1) {
          this.labels.push(month)
        }

        const datasetElement = this.dataset.filter((obj: DatasetInterface) => {
          return obj.label === record.category
        })
        if (!datasetElement.length) {
          let monthValue = []
          this.labels.forEach(() => {
            monthValue.push(null)
          })

          monthValue[recordMonth] = record.value
          this.dataset.push({
            label: record.category,
            data: monthValue,
            borderColor: this.generateColor()
          })
        } else {
          datasetElement[0].data[recordMonth] = record.value
          return
        }
      })
    })
  }

  mounted() {
    this.formDataset()
  }

  public generateColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }
}

