import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "appBottomDate" }
const _hoisted_2 = { class: "appBottomMenu" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, " Last sync date: " + _toDisplayString(_ctx.lastUpdateDate), 1),
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRoutes(), (route, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index,
          to: route.name,
          class: _normalizeClass(["item", _ctx.currentRoute === route.name ? 'active' : ''])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ion-icon", {
                name: route.icon
              }, null, 8, _hoisted_4),
              _createElementVNode("strong", null, _toDisplayString(route.title), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128))
    ])
  ], 64))
}