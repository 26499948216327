
import { Options, Vue } from "vue-class-component";
import { FormatHelper } from "@/lib/formatHelper";

@Options({
    props: {
        values: Object
    }
})
export default class CurrencyValueCard extends Vue {
    public formatHelper: FormatHelper = new FormatHelper()
    public colorGenerator: Generator<string, unknown, string> = this.getCurrencyColor();
    public *getCurrencyColor() {
        while (true) {
            yield 'bg-progress-red';
            yield 'bg-progress-ocean';
            yield 'bg-progress-blue';
            yield 'bg-progress-grey';
        }
    }
}
