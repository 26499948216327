

import {Options, Vue} from "vue-class-component"

@Options({
  emits: ['close-modal'],
  props: {
    isActive: Boolean,
    cssClass: String
  }
})
export default class SlideModal extends Vue {

  public close() {
    this.$emit('close-modal')
  }
}
