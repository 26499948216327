import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = {
  key: 0,
  class: "col-md-6 col-sm-12 m-auto"
}
const _hoisted_3 = {
  key: 1,
  class: "col-md-6 col-sm-12 m-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.started)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Data is up to date "))
      : _createCommentVNode("", true),
    (_ctx.started)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Migration process "))
      : _createCommentVNode("", true)
  ]))
}