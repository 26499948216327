import { InjectionKey, toRaw } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {BillInterface} from "@/models/BillInterface";

export interface State {
    isAuth: boolean,
    allBills: BillInterface[],
    displayLocal: Array<number>
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        isAuth: false,
        allBills: [],
        displayLocal: []
    },
    mutations: {
        authorized(state){
            state.isAuth = true
        },
        unauthorized(state){
            state.isAuth = false
        },
        setBills(state, data) {
            state.allBills = data
        },
        addToDisplayLocal(state, id) {
            if (!state.displayLocal.includes(id) && Number.isInteger(id)) {
                state.displayLocal = [...toRaw(state.displayLocal), id];
            }
        },
        removeFromDisplayLocal(state, id) {
            state.displayLocal = state.displayLocal.filter(e => e !== id);
        },
        init(state) {       
            const displayLocal = localStorage.getItem('displayLocal');
            if (displayLocal) {
                state = Object.assign(state, {
                    displayLocal: JSON.parse(displayLocal)
                });
            }
        }
    },
    actions: {
        authorized (context) {
            context.commit('authorized')
        },
        unauthorized (context) {
            context.commit('unauthorized')
        },
        setBills(context, data) {
            context.commit('setBills', data)
        },
        addCompanyToLocal(context, id) {
            context.commit('addToDisplayLocal', id)
        },
        removeCompanyFromLocal(context, id) {
            context.commit('removeFromDisplayLocal', id)
        }
    }
})

store.subscribe((mutation, state) => {
    switch (mutation.type) {
        case 'addToDisplayLocal':
        case 'removeFromDisplayLocal':
            localStorage.setItem('displayLocal', JSON.stringify(state.displayLocal));
    }
});

// define your own `useStore` composition function
export function useStore () {
    return baseUseStore(key)
}