import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "appCapsule",
  class: "full-height"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ((this.$route.name !== 'login'))
            ? (_openBlock(), _createBlock(_component_header_component, {
                key: 0,
                options: { class: 'bg-primary text-light' }
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component, route }) => [
              (_openBlock(), _createBlock(_KeepAlive, { max: 4 }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
              ], 1024))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Loading... "))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications, { group: "auth" })
  ], 64))
}