

import {Options, Vue} from "vue-class-component";
import {ApiService} from "@/services/ApiService";
import {AccountsBalanceService} from "@/services/AccountsBalanceService";
import {SessionService} from "@/services/SessionService";

type Balances = {
  [key: string]: number
}

@Options({
  inject: ['apiService', 'accountsBalanceService', 'sessionService'],
})
export default class WalletCard extends Vue {
  protected accountsBalanceService!: AccountsBalanceService
  protected sessionService!: SessionService

  protected currencyBalances: Balances = {};
  protected generalCurrencyCode: string = '$';
  protected generalBalance: number = 0;

  public created() {
    this.getGeneralBalance()
  }

  public getGeneralBalance(): number {
    ApiService.getCompanyTotal(Number(this.$route.params.id)).then(res => {
      this.currencyBalances = res.items[0]
      this.generalBalance = res.total.USD
    })

    return 0;
  }
}
