import {createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

import HomePageComponent from '@/components/HomePageComponent.vue'
import LoginPageComponent from '@/components/LoginPageComponent.vue'
import LogoutPageComponent from '@/components/LogoutPageComponent.vue'
import StructureSelectPageComponent from "@/components/StructureSelectPageComponent.vue";
import LocalCompanyPage from "@/components/LocalCompanyPage.vue";
import TrxDetails from "@/components/Transaction/TrxDetails.vue"
import BillsListAll from "@/components/Bills/BillsListAll.vue";
import BillDetails from "@/components/Bills/BillDetails.vue";
import TrxListAll from "@/components/Transaction/TrxListAll.vue";
import BiometricSetup from "@/components/BiometricSetup.vue";
import Summary from "@/components/Summary/Summary.vue";
import Migrate from "@/components/Setting/Migrate.vue";
import FinancialElementsView from "@/views/FinancialElementsView.vue";
import SyncLog from "@/views/SyncLog.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: { title: 'Home page' },
        component: StructureSelectPageComponent
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: 'Login' },
        component: LoginPageComponent
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPageComponent
    },
    {
        path: '/company-details/:id',
        name: 'company-details',
        meta: { title: 'Structure select' },
        component: HomePageComponent
    },
    {
        path: '/local-company/:id',
        name: 'local-company',
        meta: { title: 'Local company' },
        component: LocalCompanyPage
    },
    {
        path: '/transaction/:id',
        name: 'transaction',
        meta: { title: 'Transaction' },
        component: TrxDetails
    },
    {
        path: '/transactions/:companyId',
        name: 'all-transactions',
        meta: { title: 'All transactions' },
        component: TrxListAll
    },
    {
        path: '/biometric-setup',
        name: 'biometric-setup',
        meta: { title: 'Biometric Setup' },
        component: BiometricSetup
    },
    {
        path: '/migrate-data',
        name: 'migrate-data',
        meta: { title: 'Migrate data' },
        component: Migrate
    },
    {
        path: '/all-bills/:companyId?',
        name: 'all-bills',
        meta: { title: 'All bills' },
        component: BillsListAll
    },
    {
        path: '/bill-details/:id',
        name: 'bill-details',
        meta: { title: 'Bill details' },
        component: BillDetails
    },
    {
        path: '/summary/:companyId',
        name: 'summary',
        meta: { title: 'Summary' },
        component: Summary
    },
    {
        path: '/finance-elements',
        name: 'finance-elements',
        meta: { title: 'Finance elements' },
        component: FinancialElementsView
    },
    {
        path: '/sync/:jobId',
        name: 'sync-log',
        meta: { title: 'Synchronization log' },
        component: SyncLog
    },
]

const router = createRouter({
    history: createMemoryHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const metaTitle: unknown = to.meta && to.meta.title ? to.meta.title : ''
    window.document.title = String(metaTitle) + ' - YFinance'

    document.body.classList.remove('bg-white')
    if (to.meta.background) {
        document.body.classList.add(String(to.meta.background))
    }

    next();
});

export default router
