import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-radio-group" }
const _hoisted_2 = ["id", "value", "name"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option,
        class: _normalizeClass(["form-radio-group-item", { active: (_ctx.value === option) }])
      }, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.getElementId(index),
          type: "radio",
          value: option,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          name: _ctx.name,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args)))
        }, null, 40, _hoisted_2), [
          [_vModelRadio, _ctx.value]
        ]),
        _createElementVNode("label", {
          for: _ctx.getElementId(index)
        }, _toDisplayString(option), 9, _hoisted_3)
      ], 2))
    }), 128))
  ]))
}