
import {Options, Vue} from 'vue-class-component';

@Options({
    props: {
        options: Array
    },
})
export default class RadioButtonsGroup extends Vue {
    public optionValue!: string
    public name!: string
    public value: string = ''

    protected getElementId(index: number) {
        return 'radio-' + this.name + '-' + index
    }
    created() {
        this.value = this.optionValue
    }
    public changeValue() {
        this.$emit('update:optionValue', this.value)
    }
}
