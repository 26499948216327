
import {Options, Vue} from "vue-class-component";
import {RouteElement} from "@/models/RouteElement";
import {ApiService} from "@/services/ApiService";

@Options({
    inject: ['apiService'],
    watch: {
        '$route' (){
            this.currentRoute = this.$route.path
        }
    }
})
export default class BottomNavbar extends Vue {
    protected apiService!: ApiService
    protected lastUpdateDate = ''
    public currentRoute = '/'

    public routes = [
        { name: '/', title: 'Overview', icon: 'pie-chart-outline' },
        { name: '/employees', title: 'Employees', icon: 'people-outline' },
        { name: '/settings', title: 'Settings', icon: 'settings-outline' },
    ]

    created() {
        this.currentRoute = this.$route.path

    }

    getRoutes(): RouteElement[] {
        return this.routes as RouteElement[];
    }

}
