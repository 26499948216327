
import {Vue, Options} from "vue-class-component";
import SettingsComponent from "@/components/SettingsComponent.vue";
import HeaderValuesComponent from "@/components/HeaderValuesComponent.vue";
import {ApiService} from "@/services/ApiService";

export interface CurrencyInterface {
  [key: string]: number;
}

@Options({
  components: {
    SettingsComponent,
    HeaderValuesComponent
  },
  props: {
    options: Object
  }
})

export default class HeaderComponent extends Vue {
  protected apiService!: ApiService

  public async created() {
    await this.loadCurrencies()
  }

  public showHistory($router:any) {
    $router.back();
  }

  public showBack($route: any) {
    return $route.meta.title !== 'Home page';
  }

  public generalCurrencyObject: Array<CurrencyInterface> = [{ "USD": 0, "EUR": 0, "PLN": 0 }]

  protected async loadCurrencies(): Promise<void> {
    const currencyResponse = await ApiService.getCurrencies();
    currencyResponse.forEach(currency => {
      if (currency['data']['code'] !== 'UAH') {
        this.generalCurrencyObject[0][currency['data']['code']] = currency['data']['rate'];
      }
    });
  }
}
