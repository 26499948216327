
import { computed, defineComponent } from "vue";
import { LineChart, useLineChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  name: "LineGraph",
  components: { LineChart },
  props: {
    dataset: Array,
    labels: Array
  },
  setup(props) {
    const getData = computed<ChartData<"line">>(() => ({
      labels: props.labels,
      datasets: JSON.parse(JSON.stringify(props.dataset)),
    }));

    const options = computed<ChartOptions<"line">>(() => ({
      responsive: false,
      maintainAspectRatio: false,
      borderWidth: 2,
      plugins: {
        datalabels: {
          display: false
        }
      }
    }));

    const { lineChartProps } = useLineChart({
      options,
      chartData: getData,
      width: 1000,
    });

    return {
      getData,
      options,
      lineChartProps,
    };
  },
});
