import {App} from 'vue';

export function numberFormatting(num: number | string) {
    if (isNaN(<number>num)) {
        num = 0
    }

    let numberValue = Number(num)
    /** To avoid value -0 */
    if (numberValue === 0) {
        numberValue = 0
    }
    /**
     uk-ua Local return format: 1 000 000.00
     */
    return numberValue.toLocaleString('uk-ua', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export class FormatHelper {
    public formatNumber = function (num: number | string) {
        return numberFormatting(num)
    }

    public validEmail = function (email: string) {
        const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return res.test(email);
    }
}

declare module '@vue/runtime-core' {
    /**
     It`s allow to use global function $formatNumber in <template>,

     but you need to provide/inject in component script section
     */
    interface ComponentCustomProperties {
        $formatNumber: (key: string|number) => string
    }
}

export default {
    install(app: App, string: number | string) {
        app.config.globalProperties.$formatNumber = (value: number | string) => numberFormatting(value)
    }
}
