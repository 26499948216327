import {Session} from "@/models/Session";
import {AsyncInit} from "@/lib/AsyncInit";

export class SessionService extends AsyncInit {

    protected session?: Session

    constructor() {
        super()
        this.initPromise = this.init()
    }

    protected async init() {
        await this.checkSession()
    }

    protected async checkSession(): Promise<void> {
        const serializedSession: string | null = localStorage.getItem('session')
        if (serializedSession === null) {
            return
        }

        this.session = (JSON.parse(serializedSession) as Session)
    }

    public getSession(): Session | undefined {
        return this.session
    }

    public removeSession(): void {
        this.session = undefined
        localStorage.removeItem('session')
    }

    protected saveSession(): void {
        localStorage.setItem('session', JSON.stringify(this.session))
    }

    public setStructure(structureName: string): void {
        this.session!.structure = structureName
        this.saveSession()
    }

    public setLoginData(email: string, token: string): void {
        this.session = {
            email: email,
            idToken: token
        };

        this.saveSession()
    }

    public getIdToken(): string | undefined {
        return this.session?.idToken
    }
}
