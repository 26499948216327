

import {Options, Vue} from "vue-class-component";
import {FinancialElement} from "@/models/FinancialElement";
import router from "@/router";
import {ApiService} from "@/services/ApiService";

@Options({
    inject: ['apiService'],
    props: {
        financialElement: {
            type: Object as () => FinancialElement
        }
    }
})
export default class FinancialElementBlock extends Vue {
    protected apiService!: ApiService

    public getBalance(): string {
        return 'One'
    }

    public showTransactions(financialElement: FinancialElement): void {
        router.push('/transactions/' + financialElement.id)
    }
}
