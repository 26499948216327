import axios from "axios";
import router from "@/router";
import {store} from '@/vuex/store'
import {ApiService} from "@/services/ApiService";
import {CollectionInterface} from "@/models/CollectionInterface";

export class ApiClient {

    static async requestAuthorized(method: string, url: string, data?: object): Promise<CollectionInterface> {
        const token = localStorage.getItem('token') || '';

        if (!token) {
            await ApiService.logout()
        }

        const requestOptions = {
            method: method,
            url: process.env.VUE_APP_BASE_URL + url,
            headers: {
                'X-Auth-Token': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        return await axios(requestOptions)
            .then(response => {
                store.dispatch('authorized')
                return response.data;
            })
            .catch(err => {
                if (err.response.status === 401) {
                    ApiService.logout()
                } else {
                    router.push('/')
                }
            })
    }

    static async requestAnonymous(method: string, url: string, data?: object): Promise<CollectionInterface> {
        const requestOptions = {
            method: method,
            url: process.env.VUE_APP_BASE_URL + url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };

        return await axios(requestOptions)
            .then(response => {
                store.dispatch('authorized')
                router.push('/')

                return response.data;
            })
            .catch(err => {
                console.error(err)
                throw err.response.data.error
            })
    }
}
