
import { Options, Vue } from "vue-class-component";
import BottomNavbar from "@/components/layout/BottomNavbar.vue"
import { ApiService } from "./services/ApiService";
import HeaderComponent from '@/components/layout/HeaderComponent.vue'
import { store } from "@/vuex/store";

@Options({
  components: {
    BottomNavbar,
    HeaderComponent,
  },
  watch: {
    '$route'() {
      this.isAuth = store.state.isAuth
    }
  }
})
export default class App extends Vue {
  public isAuth: boolean = false
  protected loading: boolean = true
  public reload() {
    return new Promise((resolve, reject) => {
      window.location.reload();
    });
  }

  public async created(): Promise<void> {
    let isDontReload = localStorage.getItem('reloadWithoutLogin');
    
    if (isDontReload !== 'yes') {
      await ApiService.logout();
    } else {
      localStorage.removeItem('reloadWithoutLogin');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.$wb) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.$wb.addEventListener("waiting", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$wb.showUpgradeUI = true;
      });
    }

    const scripts = [
      { src: 'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js', type: 'module' },
      { src: 'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js', type: 'nomodule' },
    ];
    scripts.forEach(function (script) {
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        script.src
      );
      plugin.type = script.type;
      plugin.async = true;
      document.body.appendChild(plugin);
    });

    this.loading = false
    store.commit('init');
    let token = localStorage.getItem('token');
    if (token) {
      this.isAuth = store.state.isAuth
    } else {
      await ApiService.logout()
    }
  }
}
