import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "progress" }
const _hoisted_2 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, currency) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["progress-bar", [_ctx.colorGenerator.next().value]]),
        key: currency,
        style: _normalizeStyle('width:' + (value ?? 0) + '%'),
        role: "progressbar",
        "aria-valuenow": value ?? 0,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, _toDisplayString(currency) + " " + _toDisplayString(_ctx.formatHelper.formatNumber(value || 0)) + " % ", 15, _hoisted_2))
    }), 128))
  ]))
}