import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "bills__all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_bill_card = _resolveComponent("bill-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_component, { options: { class: 'bg-primary text-light'} }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billsList, (bill) => {
          return (_openBlock(), _createBlock(_component_bill_card, {
            class: "bills__card",
            key: bill.id,
            data: bill
          }, null, 8, ["data"]))
        }), 128))
      ])
    ])
  ], 64))
}