import { Company } from "@/lib/CompanyInterface";
import { ApiService } from "@/services/ApiService";
type Balance = {
    id: number,
    value: number
}
export class SelectedService {
    public loadBalance(id: number) {
        const balancePromise = ApiService.getCompanyTotal(id);

        return balancePromise.then((data) => {
            return {
                id: id,
                value: data.total.USD
            };
        });
    }

    private async loadCompanies(include: Array<number>, exclude: Array<number>): Promise<Company[]> {
        const balancePromises = [];
        for (const id of include) {
            balancePromises.push(this.loadBalance(id));
        }
        const companiesRequest = await ApiService.getCompanies();
        let companies = [];
        for (const company of companiesRequest.data) {
            const structure = company.data as Company;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            structure.id = structure.company;
            if (exclude.includes(structure.id)) {
                continue;
            }
            if (include.length > 0 && !include.includes(structure.id)) {
                continue;
            }
            if (!include.includes(structure.id)) {
                balancePromises.push(this.loadBalance(structure.id));
            }
            companies.push(structure);
        }

        const balances = await Promise.all(balancePromises);
        companies = companies.map((company: Company) => {
            company.balance = (balances.find((balance: Balance) => balance.id == company.id)?.value || 0);
            return company;
        });

        return companies;
    }

    public async loadSelected(ids: Array<number>): Promise<Company[]> {
        return this.loadCompanies(ids, []);
    }

    public async all(): Promise<Company[]> {
        return this.loadCompanies([], []);
    }
}