
import {Vue} from "vue-class-component";
import {ApiService} from "@/services/ApiService";

Object.defineProperty(navigator, 'userAgent', {
  get: () => undefined,
  configurable: true
});

export default class BiometricSetup extends Vue {
  public registerBiometric = async () => {
    await ApiService.registerBiometric()
  };
  public checkBiometric = async () => {
    await ApiService.checkBiometric()
  };
}
