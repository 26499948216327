
  import { Options, Vue } from 'vue-class-component';
  import {SessionService} from "@/services/SessionService";
  import router from "@/router";

  @Options({
  inject: ['sessionService'],
})
  export default class LogoutPageComponent extends Vue {
      protected sessionService!: SessionService
      public created() {
          this.sessionService.removeSession()
          router.push('/login')
      }
}
