import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "action-sheet-content" }
const _hoisted_6 = {
  key: 0,
  class: "modal-backdrop fade show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, {
      name: "slide",
      duration: 200,
      type: "animation"
    }, {
      default: _withCtx(() => [
        (_ctx.isActive)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["modal fade show", _ctx.cssClass]),
              style: {"display":"block"},
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
            }, [
              _createElementVNode("div", {
                class: "modal-dialog",
                role: "document",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h5", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "title")
                    ]),
                    (_ctx.cssClass === 'modal-box')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: "#",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
                        }, "Close"))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _renderSlot(_ctx.$slots, "body")
                    ])
                  ])
                ])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ], 64))
}