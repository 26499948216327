
import { Options, Vue } from "vue-class-component";
import { ApiService } from "@/services/ApiService";
import LocalCompany from "@/components/LocalCompany.vue"
import Total from "@/components/Total.vue"
import { Company } from "@/lib/CompanyInterface";
import router from "@/router";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { FormatHelper } from "@/lib/formatHelper";
import CurrencyValueCard from "@/components/Currency/CurrencyValueCard.vue";
import SettingStructure from "@/components/SettingStructureComponent.vue";
import { SelectedService } from "@/services/Company/SelectedService";
import { store } from "@/vuex/store";
import { computed } from "vue";

@Options({
  inject: ['apiService', 'accountsBalanceService', 'sessionService'],
  components: {
    LocalCompany,
    Total,
    Splide,
    SplideSlide,
    SplideTrack,
    CurrencyValueCard,
    SettingStructure
  }
})
export default class StructureSelectPageComponent extends Vue {
  protected apiService!: ApiService
  protected selectedService: SelectedService = new SelectedService()
  protected structures: Array<Company> = []
  protected sliderTitle: (string | null) = 'Companies'
  protected sliderTitleArray: Array<(string | null)> = []
  protected formatHelper: FormatHelper = new FormatHelper()
  public filteredStructures = computed(() => {
    return this.structures.filter((s) => this.showCompany(s.id));
  });
  public totalBalance = computed(() => {
    let totalBalance: number = 0;
    this.structures.forEach((s) => {
      if (this.showCompany(s.id)) {
        totalBalance += (s?.balance || 0);
      }
    });

    return totalBalance;
  })

  public async created() {
    await this.initializeStructures()
  }

  public mounted() {
    document.querySelectorAll('.title').forEach(el => {
      this.sliderTitleArray.push(el.textContent)
    })
    this.sliderTitle = this.sliderTitleArray[0]
  }

  public showCompany(id: number): boolean {
    return !store.state.displayLocal.includes(id);
  }

  public selectStructure(id: number): void {
    router.push(`/company-details/${id}`)
  }

  public onArrowsMounted(splide: typeof Splide) {
    this.sliderTitle = this.sliderTitleArray[splide.index + 1]
    this.onDrug()
  }
  public onDrug() {
    document.querySelectorAll('.total').forEach(el => {
      el.classList.add('hidden')
    })
  }
  public onMoved() {
    document.querySelectorAll('.total.hidden').forEach(el => {
      el.classList.remove('hidden')
    })
  }

  protected async initializeStructures(): Promise<void> {
    this.structures = await this.selectedService.all();
  }
}
