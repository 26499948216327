
import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    data: Object
  }
})

export default class HeaderValuesComponent extends Vue {
}
