import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "section full narrow" }
const _hoisted_2 = { class: "in" }
const _hoisted_3 = { class: "totalCurrencies" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (currencyBalance, key, index) => {
          return (_openBlock(), _createElementBlock("span", { key: key }, _toDisplayString(currencyBalance) + " " + _toDisplayString(key) + " " + _toDisplayString((Object.keys(_ctx.data).length - 1) !== index ? ' • ' : ''), 1))
        }), 128))
      ])
    ])
  ]))
}