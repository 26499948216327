

import {Options, Vue} from "vue-class-component";
import {ApiService} from "@/services/ApiService";
import RadioButtonsGroup from "@/components/partial/RadioButtonsGroup.vue";
import {FinancialElement} from "@/models/FinancialElement";

@Options({
    components: {RadioButtonsGroup},
    inject: ['apiService']
})
export default class FinancialElementAddForm extends Vue {
    protected apiService!: ApiService

    public currentColor: string = 'badge-primary';

    public financialForm: FinancialElement = {
        id: 0,
        balance: 0,
        currency: 'USD',
        type: 'FOP',
        color: 'badge-primary',
        holder: ''
    }

    public type = [
        'TOV',
        'FOP',
        'CARD',
        'CASH',
        'SAFE',
        'CRYPTO'
    ]

    public currencies = [
        'EUR',
        'USD',
        'UAH'
    ]

    public colors = [
        'badge-primary',
        'badge-secondary',
        'badge-success',
        'badge-danger',
        'badge-warning',
        'badge-info',
        'badge-dark',
        'badge-light'
    ]

    public async submitForm() {
        if (this.financialForm.holder === '') {
            alert('Field holder cannot be empty');
        }

        this.financialForm.color = this.currentColor;

    }
}
