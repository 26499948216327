import { Company } from "@/lib/CompanyInterface";

export class LocalService {
    private static updateLocalCompanies(company: Company[]) {
        localStorage.setItem('YourLocCompanies', JSON.stringify(company))
    }
    static updateLocalCompany(company: Company) {
        const arr: Company[] = this.getLocalCompanies()
        arr.forEach((el: Company, index: number) => {
            if (company.id === el.id) {
                arr[index] = company
            }
        })
        this.updateLocalCompanies(arr)
    }
    static getLocalCompanies() {
        return JSON.parse(localStorage.getItem('YourLocCompanies') || '[]');
    }
    static getLocalCompany(id: number) {
        return this.getLocalCompanies().find((el: Company) => el.id === id)
    }
    static getLocalCompanyByName(name: string): Company
    {
        return this.getLocalCompanies().find((el: Company) => el.name === name)
    }
    static submitNewLocal(name: string): number
    {
        if (name) {
            const newLocComp: Company = { id: 0, name: '', balance: 0, bills: [] };
            newLocComp.name = name;

            const yourLocComps = this.getLocalCompanies()
            yourLocComps.push(newLocComp);
            yourLocComps.forEach((o: Company, i: number) => o.id = i + 1);
            this.updateLocalCompanies(yourLocComps);
            return newLocComp.id;
        }

        throw new Error('name can not be empty');
    }

    static removeCompany(id: number) {
        let companies = this.getLocalCompanies()
        companies.forEach((el: Company) => {
            if (el.id === id) {
                companies = Object.values(companies as Company[]).filter((item: Company) => item.id !== id);
            }
        })
        this.updateLocalCompanies(companies)
    }
    static getTotalBalance(): number {
        let total = 0
        LocalService.getLocalCompanies().forEach((el: Company) => {
            total += el.balance;
        })
        return total;
    }

}
