
import { Options, Vue } from "vue-class-component";
import { CompanyInterface } from "@/models/CompanyInterface";
import { store } from "@/vuex/store";
import { computed, PropType } from "vue";

@Options({
    props: {
        'company': Object as PropType<CompanyInterface>
    }
})
export default class SetttingStructureComponent extends Vue {


    public isDisplayLocal = computed(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return store.state.displayLocal.includes(this.company.company);
    });
    public menuOpened: boolean = false
    public toggleMenu() {
        this.menuOpened = !this.menuOpened
    }

    public async toggleCheckbox() {

        if (!this.isDisplayLocal) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            await store.dispatch('addCompanyToLocal', this.company.company);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            await store.dispatch('removeCompanyFromLocal', this.company.company);
        }
    }


    async created() {
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                this.menuOpened = false
            }
        })
    }
}
