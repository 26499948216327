import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "col-md-6 col-sm-12 m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary btn-block btn-lg",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.registerBiometric && _ctx.registerBiometric(...args)))
      }, "Register biometric")
    ])
  ]))
}