import {ApiClient} from "@/services/ApiClient";
import router from "@/router";
import {store} from "@/vuex/store";
import {TrxInterface} from "@/models/TrxInterface";
import {SummaryInterface} from "@/models/SummaryInterface";
import {UserDataInterface} from "@/models/UserDataInterface";
import {notify} from "@kyvg/vue3-notification";
import {BillInterface} from "@/models/BillInterface";
import {PaginationInterface} from "@/models/PaginationInterface";
import {CollectionElementInterface} from "@/models/CollectionElementInterface";
import {CollectionInterface} from "@/models/CollectionInterface";
import {TransactionFilterInterface} from "@/models/TransactionFilterInterface";
import {FormatHelper} from "@/lib/formatHelper";
import { CurrencyValueFilterInterface } from "@/models/CurrencyValueFilterInterface";
import { TotalOwnershipInterface } from "@/models/TotalOwnershipInterface";
import {CurrencyResponse} from "@/models/CurrencyResponse";

export class ApiService {

    public static formatHelper: FormatHelper = new FormatHelper()

    static async loginByPassword(email: string, password: string) {
        if (!this.formatHelper.validEmail(email) || password.length < 4) {
            notify({
                group: 'auth',
                text: 'Validation error',
                type: "error"
            })

            return false
        }

        await ApiClient.requestAnonymous(
            'post',
            '/api/user/login',
            { email: email, password: password }
        ).then((res) => {
            localStorage.setItem('token', res.data.token);
        }).catch(err => {
            notify({
                group: 'auth',
                text: err,
                type: "error"
            })
        })
    }

    static async getMigrateData() {
        return await ApiClient.requestAnonymous(
            'GET',
            '/migrate',
        );
    }

    static async logout() {
        localStorage.removeItem('token')
        await store.dispatch('unauthorized')
        await router.push('/login')
    }

    static getCompanies() {
        return ApiClient.requestAuthorized('get', '/api/user/companies')
    }

    static async getCurrencies(): Promise<[]> {
        const response = await ApiClient.requestAuthorized('get', '/api/currencies')

        return response.data
    }

    static async getCompanyInfo(companyId: number) {
        const response = await ApiClient.requestAuthorized('get', '/api/company/' + companyId)

        return response.data
    }

    static async getCompanyTransactions(
        companyId: number,
        filter: TransactionFilterInterface,
        page: number
    ): Promise<{ data: CollectionElementInterface[]; pagination: PaginationInterface }> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const filterUrl: string = '?page=' + page + '&' + new URLSearchParams(filter).toString();
        const response = await ApiClient.requestAuthorized('get', '/api/company/' + companyId + '/transactions' + filterUrl)

        return { data: response.data, pagination: response.meta.pagination}
    }

    static async getTransactionById(transactionId: number): Promise<TrxInterface> {
        const response = await ApiClient.requestAuthorized('get', '/api/transactions/' + transactionId)

        return response.data
    }

    static async getBills(): Promise<BillInterface[]> {
        const response = await ApiClient.requestAuthorized('get', '/api/bills')

        return response.data
    }

    static async getBillById(billId: number): Promise<BillInterface> {
        const response = await ApiClient.requestAuthorized('get', '/api/account/' + billId)

        return response.data
    }

    static async getCompanyBills(companyId: number): Promise<CollectionInterface[]> {
        const response = await ApiClient.requestAuthorized('get', '/api/company/' + companyId + '/account')

        return response.data
    }

    static async getCompanyTotal(companyId: number) {
        const response = await ApiClient.requestAuthorized('get', '/api/total/company/' + companyId)

        return response.data
    }

    static async getAccountTotal(accountId: number) {
        const response = await ApiClient.requestAuthorized('get', '/api/total/account/' + accountId)

        return response.data
    }

    static async getCompanyOwnershipTotal(companyId: number): Promise<TotalOwnershipInterface> {
        const response = await ApiClient.requestAuthorized('get', '/api/total/ownership/company/' + companyId)

        return response.data
    }

    static async getStatisticByYear(companyId: number, year: number): Promise<CollectionElementInterface[]> {
        const response = await ApiClient.requestAuthorized('get', '/api/company/' + companyId + '/statistic?year=' + year)

        return response.data
    }

    static async getCurrencyValues(
        page?: number,
        filter?: CurrencyValueFilterInterface,
    ) {
        let filterUrl: string = '?page=' + (page ?? 1);
        if (filter) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filterUrl += '&' + new URLSearchParams(filter).toString();
        }
        const response = await ApiClient.requestAuthorized('get', '/api/currency_values/company' + filterUrl)

        return { data: response.data, pagination: response.meta.pagination}
    }

    static getSummary(): SummaryInterface[] {
        return require("../../summary.json")
    }

    static arrayBufferToBase64(ab: any){
        const dView = new Uint8Array(ab);
        const arr = Array.prototype.slice.call(dView);
        const arr1 = arr.map(function(item){
            return String.fromCharCode(item);
        });
        return window.btoa(arr1.join(''));

    }

    static base64ToArrayBuffer(base64: any) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    static registerBiometric = async () => {
        let randomChallenge: number[] = []
        let userData:  UserDataInterface = {}
        for (let i = 0; i < 32; i++) {
            randomChallenge[i] = Math.floor(Math.random() * 1000)
        }
        localStorage.setItem('challenge', randomChallenge.toString())
        await ApiClient.requestAuthorized(
            'get',
            '/api/user/profile',
        ).then((res) => {
            userData = res.data.user
        }).catch(err => {
            notify( {
                group: 'auth',
                text: err,
                type: "error"
            })
        })
        const publicKey: any = {
            rp: {
                name: userData.email,
            },
            user: {
                id: Uint8Array.from(
                    String(userData.id), c => c.charCodeAt(0)),
                name: userData.email,
                displayName: userData.email
            },
            pubKeyCredParams: [{
                type: "public-key",
                alg: -7
            }],
            attestation: "direct",
            timeout: 60000,
            challenge: new Uint8Array(randomChallenge).buffer
        };


        navigator.credentials.create({publicKey})
            .then((cred: any) => {
                const publicKeySaved: any = cred
                randomChallenge = Object.assign([], localStorage.getItem('challenge'));

                localStorage.setItem('credentialId', ApiService.arrayBufferToBase64(publicKeySaved.rawId));
                ApiClient.requestAuthorized(
                    'post',
                    '/api/user/public-key/register',
                    { public_key: ApiService.arrayBufferToBase64(publicKeySaved.rawId)}
                ).then((res) => {
                    localStorage.setItem('token', res.data.token);
                }).catch(err => {
                    localStorage.setItem('token', 'hvh');
                    notify({
                        group: 'auth',
                        text: err,
                        type: "error"
                    })
                })
            })
            .catch((err) => {
                notify({
                    group: 'auth',
                    text: err,
                    type: "error"
                })
            });
    };
    static checkBiometric = async () => {
        const randomChallenge: number[] = Object.assign([], localStorage.getItem('challenge'))
        const publicKeySaved: any = ApiService.base64ToArrayBuffer(localStorage.getItem('credentialId'))
        const getCredentialDefaultArgs: any = {
            timeout: 60000,
            allowCredentials: [{
                id: publicKeySaved,
                transports: ["usb", "nfc", "ble", "internal"],
                type: "public-key"
            }],
            challenge: new Uint8Array(randomChallenge).buffer
        };

        navigator.credentials.get({
            publicKey: getCredentialDefaultArgs
        })
            .then((cred: any) => {
                if (!cred) {
                    throw new Error()
                }

                ApiClient.requestAnonymous(
                    'post',
                    '/api/user/public-key/login',
                    {
                        public_key: ApiService.arrayBufferToBase64(cred.rawId),
                    }
                ).then((res) => {
                        localStorage.setItem('token', res.data.token);
                    }
                ).catch(err => {
                    notify({
                        group: 'auth',
                        text: "API ERROR: " + err,
                        type: "error"
                    })
                })
            }).catch((err) => {
                notify({
                group: 'auth',
                text: " Havigator Error: " + err,
                type: "error"
            })
        });

    };

    static async runSync(command: string): Promise<number> {
        const response = await ApiClient.requestAuthorized(
            'post',
            '/api/sync',
            {
                command: command
            }
        )

        return Number(response.data.jobId)
    }

    static async getJobLog(jobId: number, lastId: number): Promise<CollectionElementInterface[]> {
        const response = await ApiClient.requestAuthorized('get', '/api/sync/' + jobId + '?lastId=' + lastId)

        return response.data
    }

    static async getJobStatus(jobId: number): Promise<any> {
        const response = await ApiClient.requestAuthorized('get', '/api/sync/' + jobId + '/status')

        return response.data
    }
}
