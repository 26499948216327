
import {Options, Vue} from 'vue-class-component';
import FinancialElementBlock from '@/components/partial/_financialElement.vue';
import {FinancialElement} from "@/models/FinancialElement";
import {ApiService} from "@/services/ApiService";
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import SlideModal from "@/components/modal/slide-modal.vue";
import FinancialElementAddForm from "@/components/partial/_financialElementAddForm.vue";

@Options({
    inject: ['apiService'],
    components: {
        HeaderComponent,
        FinancialElementBlock,
        SlideModal,
        FinancialElementAddForm
    }
})
export default class FinancialElementsView extends Vue {
    protected apiService!: ApiService
    protected financialElements: FinancialElement[][] = []

    protected relevantColors: string[] = [];
    protected colorsList: string[] = [
        'badge-primary',
        'badge-secondary',
        'badge-success',
        'badge-danger',
        'badge-warning',
        'badge-info',
        'badge-dark'
    ]

    public created() {
        this.setFinancialElements()
    }

    public async setFinancialElements(): Promise<void> {

        let financialElements: FinancialElement[][] = [];


        this.financialElements = financialElements
    }

    protected getRandomColor(): string {
        if(this.relevantColors.length === 0) {
            this.relevantColors = [...this.colorsList]
        }

        const colorIndex = Math.floor(Math.random()*this.relevantColors.length)
        const color = this.relevantColors[colorIndex]
        this.relevantColors.splice(colorIndex, 1);

        return color
    }
}
