import { ApiService } from "@/services/ApiService";
import { CurrencyValueKey } from "@/lib/Currency/CurrencyValueKey";
import { PaginationInterface } from "@/models/PaginationInterface";
import { CollectionElementInterface } from "@/models/CollectionElementInterface";
import { CompanyCurrencyValueInterface } from "@/models/CompanyCurrencyValueInterface";
import { CurrencyValueFilterInterface } from "@/models/CurrencyValueFilterInterface";

export class CurrencyValueService {
    public async all(filter: CurrencyValueFilterInterface = {}): Promise<CurrencyValueKey> {
        const structureCurrencyValues: CurrencyValueKey = {}
        const currencyValuesRequest = await ApiService.getCurrencyValues(1, filter);
        let currValues: Array<CompanyCurrencyValueInterface> = this.convertCurrencyValueResponse(currencyValuesRequest);
        if (currencyValuesRequest.pagination.pages.total > 1) {
            for (let i: number = 2; i <= currencyValuesRequest.pagination.pages.total; ++i) {
                const response = await ApiService.getCurrencyValues(i, filter);
                const items: Array<CompanyCurrencyValueInterface> = this.convertCurrencyValueResponse(response);
                currValues = currValues.concat(items);
            }
        }
        for (const currencyValue of currValues) {
            const company = Object.keys(currencyValue)[0];
            structureCurrencyValues[company] = Object.assign(structureCurrencyValues[company] ?? {}, {
                [currencyValue[company].type]: Object.assign({}, structureCurrencyValues[company] ? structureCurrencyValues[company][currencyValue[company].type] : {}, {
                    [currencyValue[company].currency]: currencyValue[company].value
                })
            });
        }

        return structureCurrencyValues;
    }

    public async getCompanyValues(company: string) {
        return await this.all({company: company});
    }

    private convertCurrencyValueResponse(response: { data: CollectionElementInterface[]; pagination: PaginationInterface }) {
        return response.data.map((currencyValueElement: CollectionElementInterface) => {
            return currencyValueElement.data as CompanyCurrencyValueInterface;
        });
    }
}