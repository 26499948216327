
import {Options, Vue} from "vue-class-component";
import {isProxy, toRaw} from 'vue';

@Options({
  props: {
    dataset: Array,
    labels: Array
  },
  watch:{
    'dataset': function () {
      this.formatProxy()
    }
  },
})
export default class SummaryTable extends Vue {
  public formattedDataset: object[] = [{}]
  public labels: string[] = []

  public formatProxy() {
    let rawData: any = [];
    if (isProxy(this.$props)){
      rawData = toRaw(this.$props)
    }
    this.formattedDataset = toRaw(rawData.dataset)
    this.labels = rawData.labels
  }
  mounted() {
    this.formatProxy()
  }
}

