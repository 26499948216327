import {ApiService} from "@/services/ApiService";
import {TrxInterface} from "@/models/TrxInterface";
import {CollectionElementInterface} from "@/models/CollectionElementInterface";
import {PaginationInterface} from "@/models/PaginationInterface";
import {TransactionFilterInterface} from "@/models/TransactionFilterInterface";

export class TransactionService {
    static async getCompanyTransactions(
        companyId: number,
        filter: TransactionFilterInterface,
        page: number = 1
    ): Promise<{ data: TrxInterface[]; pagination: PaginationInterface }> {
        const data = await ApiService.getCompanyTransactions(companyId, filter, page)
        const records = data.data.map((el: CollectionElementInterface) => {
            const model: TrxInterface = el.data

            return model
        })

        return {
            data: records,
            pagination: data.pagination
        }
    }

    static getTransactionData(transaction: CollectionElementInterface): TrxInterface {
        return transaction.data
    }

    static async getTransaction(transactionId: string): Promise<TrxInterface> {
        return await ApiService.getTransactionById(parseInt(transactionId))
    }
}
