import { createApp } from 'vue'
import App from './App.vue'
import wb from "./registerServiceWorker";
import router from './router'
import { SessionService } from "@/services/SessionService"
import { LocalService } from "@/services/LocalService"
import { BillServices } from "@/services/BillServices"
import {ApiService} from "@/services/ApiService";
import formatHelper  from "@/lib/formatHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/scss/style.scss'
import {AccountsBalanceService} from "@/services/AccountsBalanceService";
import { store } from './vuex/store'
import Notifications from '@kyvg/vue3-notification'

import 'vue-select/dist/vue-select.css';

const sessionService = new SessionService()
const localService = new LocalService()
const billServices = new BillServices()
const apiService = new ApiService()
const accountsBalanceService = new AccountsBalanceService(apiService);

createApp(App)
    .use(router)
    .use(store)
    .use(Notifications)
    .use(formatHelper)
    .provide('formatHelper', formatHelper)
    .provide('apiService', apiService)
    .provide('sessionService', sessionService)
    .provide('localService', localService)
    .provide('billServices', billServices)
    .provide('accountsBalanceService', accountsBalanceService)
    .provide('$workbox', wb)
    .mount('#app')