

import { Vue } from "vue-class-component";
import { MigrateDataService } from "@/services/Setting/MigrateDataService";

export default class Migrate extends Vue {
  public started: boolean = false;
  public startMigrate = async () => {
    this.started = true;
    let migrateService = new MigrateDataService();
    await migrateService.migrate();
    this.started = false;
  }
  mounted() {
    this.startMigrate();
  }
}
