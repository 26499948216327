
import {Options, Vue} from "vue-class-component";
import {BillInterface} from "@/models/BillInterface";
import {PropType} from "vue";

@Options({
  props: {
    data: Object as PropType<BillInterface>
  }
})

export default class BillCard extends Vue {
  public goDetails(billId: number) {
    this.$router.push({path: `/bill-details/${billId}`});
  }
}
