
import {Options, Vue} from "vue-class-component";
import {BillInterface} from "@/models/BillInterface";
import BillCard from "@/components/Bills/BillCard.vue";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import {BillServices} from "@/services/BillServices";

@Options({
  inject: ['billServices'],
  components: {
    BillCard,
    Splide,
    SplideSlide,
    SplideTrack
  }
})

export default class BillsList extends Vue {
  public billsList: BillInterface[] = []
  public options: object = {
    perPage: 4,
    breakpoints: {
      1024: {
        perPage: 3,
      },
      768: {
        perPage: 2,
      },
      425: {
        perPage: 1,
      }
    }
  }

  async mounted() {
    const companyId = Number(this.$route.params.id)
    const bills = await BillServices.getCompanyBills(companyId)
    this.billsList = await BillServices.groupBills(bills)
  }
}
