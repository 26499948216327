
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import SlideModal from "@/components/modal/slide-modal.vue";
import { LocalService } from "@/services/LocalService";
import { Company } from "@/lib/CompanyInterface";
import { FormatHelper } from "@/lib/formatHelper"
import { ApiService } from "@/services/ApiService";
import { CompanyInterface } from "@/models/CompanyInterface";
import { CollectionElementInterface } from "@/models/CollectionElementInterface";

interface CompanyPart {
  name: string;
  balance: number;
  balancePart: number;
  part: number;
  link: string;
  color: string;
  sort: number;
}

type PercentTotal = {
  id: number;
  value: number;
  percent: number;
}

type DefaultTotal = {
  id: number;
  value: number;
}

@Options({
  components: {
    SlideModal
  }
})

export default class Total extends Vue {
  fullTotal: number = 0;
  youTotal: number = 0;
  yourLocComps: Company[] = [];
  companies: Array<CompanyPart> = [];

  protected apiService!: ApiService
  protected formatHelper: FormatHelper = new FormatHelper()

  public loadOwnerBalance(id: number) {
    const balancePromise = ApiService.getCompanyOwnershipTotal(id);

    return balancePromise.then((data) => {
      return {
        id: id,
        value: data.total.USD,
        percent: data.percent
      };
    });
  }
  public loadBalance(id: number) {
    const balancePromise = ApiService.getCompanyTotal(id);

    return balancePromise.then((data) => {
      return {
        id: id,
        value: data.total.USD,
      };
    });
  }
  mounted() {
    this.yourLocComps = LocalService.getLocalCompanies()
    // set balance
    this.yourLocComps.map((localCorp) => {
      this.fullTotal += localCorp.balance;
      this.youTotal += localCorp.balance;

      this.companies.push({
        'name': localCorp.name,
        'balance': localCorp.balance,
        'balancePart': localCorp.balance,
        'part': 100,
        'link': `/local-company/${localCorp.id}`,
        'color': '#706e6e',
        'sort': 3,
      });
    });

    // set companies
    const structuresRequest = ApiService.getCompanies().then((data) => {
      data.data.map((structureElement: CollectionElementInterface) => {
        const structure = structureElement.data as CompanyInterface
        if (!structure.company) {
          return false
        }
        const balancePromises = [];
        balancePromises.push(this.loadOwnerBalance(structure.company));
        balancePromises.push(this.loadBalance(structure.company));
        const balancePromisesResult: any = Promise.all(balancePromises);
        balancePromisesResult.then((result: any) => {
          const percentResult = result[0] as PercentTotal;
          const defaultResult = result[1] as DefaultTotal;
          const part = percentResult.percent;
          const partBalance = percentResult.value;
          this.fullTotal += defaultResult.value;
          this.youTotal += partBalance;
          const sort = structure.name === 'BUILDING' ? 2 : 1;
          this.companies.push({
            'name': structure.name,
            'balance': defaultResult.value,
            'balancePart': partBalance,
            'part': part,
            'link': `/company-details/${structure.company}`,
            'color': '#154e85',
            'sort': sort
          });
        })

        this.companies = this.companies.sort((a, b) => {
          return a.sort - b.sort;
        })
      })

    })
  }

  openCompany(link: string) {
    router.push({ path: link })
  }
}
