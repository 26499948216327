import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chartWrapper mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LineChart, _mergeProps(_ctx.lineChartProps, { cssClasses: "chart-scroll" }), null, 16)
  ]))
}