import {BillInterface} from "@/models/BillInterface";
import {CompanyBills} from "@/lib/CompanyBillsInterface";
import {Company} from "@/lib/CompanyInterface";
import {LocalService} from "@/services/LocalService";
import {ApiService} from "@/services/ApiService";
import {CollectionElementInterface} from "@/models/CollectionElementInterface";

export class BillServices {
    static async getBill(billId: number): Promise<BillInterface> {
        return await ApiService.getBillById(billId)
    }

    static filterBills(list: BillInterface[]): BillInterface[] {
        const output: BillInterface[] = [];
        list.forEach(item => {
            const existing = output.filter(function(v) {
                return v.name === item.name;
            });
            if (existing.length) {
                const existingIndex = output.indexOf(existing[0]);
                const exists = JSON.stringify(output[existingIndex].currency).includes(JSON.stringify(item.currency).slice(1, -1));
                if (!exists) {
                    output[existingIndex].currency = output[existingIndex].currency.concat(item.currency);
                }
            } else {
                output.push(item);
            }
        })
        return output
    }
    static getLocalBills() {
        let localBills: CompanyBills[] = []
        LocalService.getLocalCompanies().forEach((el: Company) => {
            localBills = localBills.concat(el.bills)
        })
        let sortedBills: BillInterface[] = [];
        localBills.forEach((bill: CompanyBills, i: number) => {
            const sorted: BillInterface = {
                name: bill.name,
                color: 'grey',
                currency: bill.currency,
                balance: bill.balance,
                id: i,
                type: 'string',
                currencies: []
            }
            sortedBills = sortedBills.concat(sorted)
        })
        return sortedBills
    }

    static async getCompanyBills(companyId: number): Promise<BillInterface[]> {
        const data = await ApiService.getCompanyBills(companyId)

        return data.map((el: CollectionElementInterface) => {
            const model: BillInterface = el.data

            return model
        })
    }

    static async groupBills(bills: BillInterface[]) {
        const billsList: BillInterface[] = []
        for (const bill of bills) {
            const index = billsList.findIndex(x => x.name === bill.name)

            const total = bill.totals
            if (!total) {
                continue
            }

            const currency: { name: string, val: number} = { name: bill.currency, val: total.items[0][bill.currency] }

            if (index >= 0) {
                const item = billsList[index]
                item.currencies.push(currency)
            } else {
                bill.currencies = []
                bill.currencies.push(currency)
                billsList.push(bill)
            }
        }

        return billsList
    }

    static async getConcatBills(): Promise<BillInterface[]> {
        const billsList: BillInterface[] = []
        BillServices.filterBills(await ApiService.getBills()).concat(BillServices.getLocalBills()).forEach((o: BillInterface, i: number) => {
            o.id = i + 1
            billsList.push(o)
        });
        return billsList
    }
    static async getBillsNames(
        companyId: number | undefined = undefined
    ) {
        const billNames: Array<string> = []
        const bills = await BillServices.filterAll(companyId)
        bills.forEach((o) => {
            billNames.push(o.name)
        });

        return billNames
    }

    static async filterAll(
        companyId: number | undefined = undefined,
        selectedName: string | undefined = undefined,
        filteredCurrencies: string[] | undefined = undefined
    ): Promise<any[]> {
        let billsList = []
        if (companyId) {
            billsList = await BillServices.getCompanyBills(companyId)
        } else {
            billsList = await BillServices.getConcatBills()
        }

        if (selectedName) {
            const newV: string = selectedName
            billsList = billsList.filter(function checkAdult(el: any) {
                return el.data.name.includes(newV);
            })
        }
        /*if (filteredCurrencies && filteredCurrencies.length) {
            const list: BillInterface[] = []
            billsList.forEach(el => {
                filteredCurrencies.forEach((filteredCurr: string) => {
                    el.currency.forEach(currentCurr => {
                        if (currentCurr.name === filteredCurr) {
                            if (list.indexOf(el) === -1) {
                                list.push(el)
                            }
                        }
                    })
                })
            })
            billsList = list
        }*/
        return billsList
    }
}
